// import { executor } from '@/utils/require';
import { AxiosRequest } from '@/request/index.js';

export default payload => {
  payload.store.registerModule('global', {
    namespaced: true,
    state: {
      token: '',
      dcId: '',
      loading: false,
      callBackToken: ''
    },
    mutations: {
      setToken: (state, data) => {
        state.token = data;
      },
      setDcId: (state, data) => {
        state.dcId = data;
      },
      setCallBackToken: (state, data) => {
        console.log('setCallBackToken: ', data);
        state.callBackToken = data;
      },
      logout: state => {
        state.token = '';
        state.dcId = '';
      },
      loading: (state, data) => {
        state.loading = data;
      }
    },
    actions: {
      actionToken: ({ commit }, data = '') => {
        commit('setToken', data);
        AxiosRequest.initRequestParams();
        AxiosRequest.registerPrivateRequest(data);
      },
      onLogout: async ({ commit }, $router) => {
        window.sessionStorage.clear();
        commit('logout');
        $router.push('/login');
      },
      onLoadingEnter: ({ commit }) => {
        commit('loading', true);
      },
      onLoadingLeave: ({ commit }) => {
        commit('loading', false);
      },
      onCallBackToken: ({ commit }, data = '') => {
        console.log('commit: ', data);
        commit('setCallBackToken', data);
      },
    },
    getters: {}
  });

  // 加载其下子模块
  // executor(require.context('./global', false, /\.vuex\.js$/), payload);
};
